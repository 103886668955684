
export default {
    showMainMenu(){
        document.getElementById('main-menu-overlay').classList.toggle('hidden', false);
        document.querySelector('body').style.overflow = 'hidden';
    },
    hideMainMenu(){
        document.getElementById('main-menu-overlay').classList.toggle('hidden', true);
        document.querySelector('body').style.overflow = 'inherit';
    }
}
