
export class MainNavigationScrollHandler {
    private isUnevenStep: boolean = true;
    private didScroll: boolean = false;
    private lastScrollY: number = 0;
    private currentScrollY: number = 0;
    private intervall: number = 250;
    private navigationHeightInPx: number = 82;


    private init() {

        if (window.onscroll != null) {
            console.error('window.onscroll is already set.')
            return;
        }

        window.onscroll = () => {
            this.onScrollHandler()
        };

        setInterval(() => {
            this.onScrollHandlerDebounced();
        }, this.intervall)

    }

    private onScrollHandler() {
        this.didScroll = true;
    }

    private onScrollHandlerDebounced() {

        if (!this.didScroll) {
            return;
        } else {
            this.didScroll = false;
        }

        if (this.isUnevenStep) {
            this.lastScrollY = scrollY;
        } else {
            this.currentScrollY = scrollY;

            if (this.lastScrollY > this.currentScrollY || this.currentScrollY < this.navigationHeightInPx) {
                this.show()
            } else {
                this.hide()
            }
        }
        this.isUnevenStep = !this.isUnevenStep;

    }

    private show() {
        document.getElementById("navigation").classList.toggle('collapsed', false);
    }

    private hide() {
        document.getElementById("navigation").classList.toggle('collapsed', true);
    }

    public start() {
        this.init();
    }

    public stop() {
        window.removeEventListener('scroll', this.onScrollHandler);
    }
}
