import EmblaCarousel from 'embla-carousel'

export default {
    init() {

        // Grab wrapper nodes
        const rootNode = document.querySelector('.emblaCategoryCarousel .embla')
        const viewportNode = rootNode.querySelector('.emblaCategoryCarousel .embla__viewport')

        // Grab button nodes
        const prevButtonNode = rootNode.querySelector('.embla__prev')
        const nextButtonNode = rootNode.querySelector('.embla__next')

        const options = {
            // loop: false,
            // dragFree: false,
            skipSnaps: true,
            align: 'start',
            // startIndex: 0,
            //containScroll: false
        };
        const emblaApi = EmblaCarousel(viewportNode, options)

        prevButtonNode.addEventListener('click', emblaApi.scrollPrev, false)
        nextButtonNode.addEventListener('click', emblaApi.scrollNext, false)
    }
}
